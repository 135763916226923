import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

import './style.scss';
import naturepassLogo from '../images/naturepass-logo.png';

const Navbar = () => (
	<StaticQuery
		query={graphql`
			query navbarSiteMetadataQuery {
				site {
					siteMetadata {
						gitlab,
						title
					}
				}
			}
		`}
		render={data =>(
			<div className="hero-head is-hidden-mobile">
				<nav className="navbar">
					<div className="navbar-background"></div>
						<div className="navbar-brand">
							<a className="navbar-item" href="/">
								<figure className="image">
									<img src={naturepassLogo} className="logo" alt="NaturePass"/>
								</figure>
								<span className="title is-uppercase">
									<span className="part1">Nature</span>
									<span className="part2">Pass</span>
								</span>
							</a>
						</div>
				</nav>
			</div>
		)}/>
	
);

export default Navbar;
