import React from 'react';

import './style.scss';

import Navbar from './navbar';

const Header = ({ siteTitle }) => (
	<section className="hero gradientBg is-fullheight-with-navbar">
		<Navbar />
		<div className="hero-body">
			<div className="container teaserText">
				<h2 className="title is-2">
					Her kommer <span className="is-bold">din portal</span> for <span className="is-bold">jakt og fiske</span> i hele Norge.
				</h2>
			</div>
		</div>
	</section>
);

export default Header;
